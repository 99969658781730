$main--color : #303036;
$second--main-color: #FFCF00;
$blue--main-color: #1c366b;

$red--main: #e84436;
$blue--main: #3BB5F5;
$blue--title: #233667;
$blue--help:#1660C6;
$transparence--bg:#F1F7FF;

$grey--border:#E7E7F2;
$comment-bg:#F0F2F5;
$yellow-bg: #fff9de;
$yellow-content: #FFCF00;
$orange-border: #FF9211;
$red--error:#FA6666;

$turquoise--content: #3FC1C9;
$grey--text: #303030;
$grey--legend:#999999;
$grey--subtitle :#58595A;
$grey--canceled :#DBDEE3;
$orange--mail:#FC5130;
$red--error:#FA6666;


// webApp colors
$color_primary_700: rgba(114,81,17,1);
$color_primary_600: rgba(159,115,29,1);
$color_primary_500: rgba(231,187,0,1);
$color_primary_400: rgba(255,207,0,1);
$color_primary_300: rgba(255,227,107,1);
$color_primary_200: rgba(252,237,175,1);
$color_primary_100: rgba(253,248,232,1);
$color_secondary_700: rgba(6,22,28,1);
$color_secondary_600: rgba(15,37,46,1);
$color_secondary_500: rgba(35,63,83,1);
$color_secondary_400: rgba(54,80,108,1);
$color_secondary_300: rgba(99,118,145,1);
$color_secondary_200: rgba(174,194,218,1);
$color_secondary_100: #F5F9FF;
$color_secondary_50:  #E7EEF7;
$color_success_100: rgba(232,249,251,1);
$color_success_400: rgba(49,194,170,1);
$color_error_700: rgba(160,43,48,1);
$color_error_400: rgb(250, 102, 102);
$color_error_100: rgba(254,244,241,1);
$color_warning_700: rgba(187,91,37,1);
$color_warning_400: rgba(255,146,17,1);
$color_warning_100: rgba(255,242,227,1);
$color_highlight_700: rgba(19,53,78,1);
$color_highlight_400: rgba(68,164,234,1);
$color_highlight_100: rgba(237,246,253,1);
$color_white: rgba(255,255,255,1);
$color_grey_100: rgba(245,249,255,1);
$color_grey_200: rgba(223,229,237,1);
$color_grey_300: rgba(192,200,211,1);
$color_grey_400: rgba(143,149,158,1);
$hub_theme: #31C2AA;
$shopper_theme: #FA6666;
$riders_theme: $color_secondary_400;
$rider--theme_400 : #44A4EA;
$color_ttc: #AEC2DA;

$color_ttc: #AEC2DA;

@mixin text_style_headline_1 {
  font-size:94px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_2 {
  font-size:59px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_3 {
  font-size:47px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_4 {
  font-size:33px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_5 {
  font-size:24px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_6 {
  font-size:20px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_subtitle_1 {
  font-size:1rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_subtitle_2 {
  font-size:0.9rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_body_1 {
  font-size:16px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_body_2 {
  font-size:14px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_button_big {
  font-size:16px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_button_medium_small {
  font-size:14px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_caption {
  font-size:12px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150px;
}
@mixin text_style_overline {
  font-size:12px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin effect_style_button_elevation {
  box-shadow: 0.00px 4.00px 10.00px rgba(54, 80, 108, 0.150);
}
@mixin transition-hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@mixin transition-hover_quick {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}


@mixin btn--style {
  display: flex;
  padding: 20px 40px;
  color: white;
  background: $blue--main-color;
  border-radius: 5px;
  margin: 20px 5px 0;
  text-decoration: none;
  font-size: 1.35em;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: transparent;
  border: none;
}

@mixin line--under-title {
  position: absolute;
  content: "";
  bottom: -15px;
  left: 0;
  max-width: 60px;
  width: 100%;
  border-radius: 8px;
  height: 7px;
  margin: 0 auto;
  right: 0;
}

@mixin slick--arrow {
  color: transparent;
  background-color: transparent;
  border: none;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  top: 0;
  outline: none;
  &:after {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    position: absolute;
    top: 40%;
    align-items: center;
    padding: 6px;
    font-size: 2em;
    font-family: 'Material Icons';
  }

}

@mixin slick--prev_after {
  left: -35px;
  content: "\e5c4";
  justify-content: flex-end;
}

@mixin slick--next_after {
  right: -35px;
  content: "\e5c8";
  justify-content: flex-start;
}

@mixin card--style {
  border-radius: 0 0 5px 5px;
  background-color: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin tooptilps {
  position: absolute;
  right: -42px;
  background: $transparence--bg;
  color: $blue--help;
  border-radius: 7px;
  border: 1px solid $blue--help;
  padding: 12px;
  bottom: -80px;
  width: 260px;
  font-size: 0.90em;;
  opacity: 0;
  @include transition-hover_quick;
}

@mixin special--input {
  .form--group {
    position: relative;
    width: 100%;
  }
  .has--search .form--control {
    width: 100%;
    display: flex;
    padding: 15px 0 15px 2rem;
    margin: 0 auto;
    border-radius: 2px;
    border: 1px solid $color_secondary_200;
    outline: none;
    caret-color: $color_secondary_200;
    color: $color_secondary_400;

    &::placeholder {
      color: $color_secondary_300;
    }
  }
  .form--control:focus {
    border: 1px solid $color_highlight_400;
    outline: none;
    caret-color: $color_highlight_400;
  }
  .has--search i {
    font-size: 0.8rem;
    position: absolute;
    z-index: 2;
    color: $color_secondary_300;
    font-weight: 300;
    align-items: center;
    display: flex;
    left: 12px;
    height: 100%;
  }
}

@mixin classic--input {
  @include special--input;
  appearance: auto;
  .has--search .form--control {
    padding: 15px;
  }
}

@mixin generalBtn {
  border: none;
  padding: 15px;
  position:relative;
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(54, 80, 108, 0.15);
  background: linear-gradient(98.15deg, #233F53 1.58%, #36506C 57.12%);
  background-size: 200% 100%;
  transition: background 0.15s, color 0.25s;
  span{
    color: white;
    position: relative;
    z-index: 2;
  }
  &:before {
    border-radius: 4px;
    top: 0;
    left: 0;
    right: -1px;
    bottom: 0;
    transform-origin: center top;
    transform: scale(1, 0) translateZ(0);
    transition: all 0.25s ease-out;
    content: '';
    display: block;
    position: absolute;
    background-color: $color_primary_400;
  }
  &:hover {
    span {
      color: $color_secondary_400;
    }
    i{
      color:$color_secondary_400;
    }
    &:before { transform: scale(1) translateZ(0); }
  }
}

@mixin backBtn {
  @include generalBtn;
  background: white;
  box-shadow: none;
  border: 1px solid $color_secondary_400;
  color: $color_secondary_400;
  span{
    color: $color_secondary_400;
    z-index: 2;
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: center top;
    transform: scale(1, 0) translateZ(0);
    transition: all 0.25s ease-out;
    content: '';
    display: block;
    position: absolute;
    border-radius: 4px;
    background-color: $color_primary_200;
  }
  &:hover {
    border-radius: 4px;
    border-color: $color_primary_400;
    color: $color_secondary_400;
    i{
      color:$color_secondary_400;
    }
    &:before { transform: scale(1) translateZ(0); }
  }
}

@mixin cbxStyle {
  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      font-family: 'manrope', sans-serif;

      &:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 3px;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid $color_secondary_200;
        transition: all 0.2s ease;
        margin-right: 8px;
      }

      &:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: $color_secondary_400;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
    }
    span.label--desc{
      font-size: 0.75rem;
      margin-left: 22px;
      line-height: 130%;
      width: 50%;
      margin-top: 5px;
      font-weight: 400;
      color:$color_secondary_300;
    }
    span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: $color_primary_400;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    span:last-child {
      padding-left: 8px;
    }

    &:hover span:first-child {
      border-color: $color_primary_400;
    }
  }
  .inp-cbx:checked + .cbx span:first-child {
    background: $color_primary_400;
    border-color: $color_primary_400;
    animation: wave 0.4s ease;
    &:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .cbx span.color--svg-error{
    border-color: #FA6666;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

@mixin radiobtn {
  .radiobtn {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);

      &:first-child {
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid $color_secondary_200;
        transition: all 0.2s ease;
      }

      &:first-child svg {
        display: none;
      }
    }

    span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: $color_primary_400;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    &:hover span:first-child {
      background: white;
      border: 4px solid $color_primary_400;
    }
  }

  .inp-radiobtn:checked + .radiobtn span:first-child {
    background: white;
    border: 3px solid $color_primary_400;
    box-shadow: 0px 1px 4px rgba(255, 207, 0, 0.25), inset 0px 1px 2px rgba(54, 80, 108, 0.1);
    animation: wave 0.4s ease;

    &:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .inp-radiobtn:checked + .radiobtn span:first-child svg {
    stroke-dashoffset: 0;
  }


  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

@mixin popover {
  font-family: "roboto", sans-serif;
  color: $color_secondary_400;
  padding: 16px;
  .popover-header {
    i {
      color: $color_highlight_400;
      margin-right: 8px;
    }
    border-bottom: none;
    font-weight: bold;
    padding: 0;
    font-size: 1em;
    display: flex;
    color: $color_secondary_400;
    align-items: center;
    background: transparent;
  }
  .popover-body {
    font-size: 0.85em;
    font-weight: 400;
    color: $color_secondary_400;
    padding: 0;
    margin-top: 10px;
  }
}

@mixin typeAheadTwitter() {
  width: 100%;
  .tt-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  .tt-menu.tt-open {
    position: absolute;
    top: 40px !important;
    left: 0;
    width: 100%;
    font-family: "roboto", sans-serif;
    font-weight: 700;
    color: $color_secondary_400;
    background: white;
    border-radius: 2px;
    border: 1px solid $color_highlight_400;
    border-top: none;
    display: none;
    overflow: scroll;
    height: 200px;
  }
  .tt-suggestion.tt-selectable {
    cursor: pointer;
    padding: 15px;
    p {
      color: $color_secondary_400;
      font-size: 0.85em;
    }
    &:hover{
      background-color: $color_highlight_100;
      padding: 15px;
    }
  }

  .render--rp_main {
    // margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 15px;
      position: relative !important;
      left: 0 !important;
    }

  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $color_secondary_200;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
}

@import "~bootstrap/scss/bootstrap";
@import "components/utils/colors";
@import "components/footer/footer.scss";
@import '../../node_modules/aos/dist/aos.css';
@import "components/utils/mixins";
@import "components/utils/headline";
@import "fonts/fonts";
@import "components/utils/loader.scss";
@import "~eric-meyer-reset.scss/reset";

html {
  overflow-x: hidden;
}

body {
  font-size: 80%;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  padding: 0;
  line-height: 150%;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}
span.bold{
  font-weight: bold;
}
h1 {
  line-height: 150%;
  @include text_style_headline_h_1;
}

h2 {
  line-height: 150%;
  @include text_style_headline_h_2;
}

h3 {
  font-weight: 400;
  line-height: 150%;
  @include text_style_headline_h_3
}

h4 {
  @include text_style_headline_h_4;
  line-height: 130%;
}

p {
  @include text_style_body_p_1;
}

.yellow-deco {
  color: $color_primary_400;
}

.btn--main {
  border-radius: 4px;
  background-color: $color_secondary_400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  padding: 18px;
  font-family: "manrope", sans-serif;
  font-weight: 500;
  margin: 0 auto;
  @include transition-hover_quick;
  &:hover {
    color: white;
    background-color: $color_primary_400;
    text-decoration: none;
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

footer {
  position: relative;

  .footer--test {
    position: relative;
    height: 300px;
    background-color: $color_secondary_400;
    width: 100%;
  }
}

@media screen and (min-width: 620px) {
  .redirection--btn {
    display: inline-flex;
  }
}

.hubspot--desktop{
  display: none;
}
.hubspot--responsive{
  display: inline-flex;
}

@media screen and (min-width: 1400px) {
  .hubspot--desktop{
    display: inline-flex;
  }
  .hubspot--responsive{
    display: none;
  }
}