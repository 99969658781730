footer {
    background-color: $color_secondary_400;
  .desktop--footer{
    display: none;
  }
  .footer--wrapper{
    flex-direction: column;
    max-width: 1240px;
    margin: 0 auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      margin: 30px auto 15px auto;
    }
  }
  .dropdown--footer{
    width: 80%;
    margin: 0 auto;
  }
  .dropdown {
    margin: 30px 0;
    text-align: center;
    width: 100%;
    .footer--link {
      color: $color_secondary_200;
      font-size: 0.75rem;
      font-family: "manrope", sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover{
        text-decoration: none;
      }
    }
    .dropdown-menu{
      border:none;
      color: white;
      background-color: transparent;
    }
    .dropdown-menu.show {
      width: 100%;
      transform: initial!important;
      border:none;
      position: relative!important;
      .dropdown-item{
        background-color: transparent;
        padding: 0;
        margin: 5px 0 15px 0;
        color: white;
        font-size: 14px;
        transition: none;
        &:hover, &:focus{
          background-color: transparent;
        }
      }
    }
    .dropdown-toggle::after{
    display: none;
    }
  }
  .bottom--footer_wapper{
    background-color: $color_secondary_700;
    padding: 40px 0;
  }
  .bottom--footer{
    flex-direction: column;
    max-width: 1240px;
    margin: 0 auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    .social--media_logo{
      width: 58%;
      padding: 20px 0;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .legal--mention{
      padding:20px 0;
      p{
        color: $color_secondary_200;
        font-size: 12px;
        display: flex;
        text-align: center;
        align-items: center;
      }
    }
  }
}

@media screen and (min-width: 670px) {
  footer {
    .responsive--footer {
      display: none;
    }
    .desktop--footer {
      display: flex;
      flex-direction: row;
      margin: 30px 0;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      .footer--title{
        display: flex;
        margin:0 20px;
        flex-direction: column;
        p.title--footer{
          margin-bottom: 10px;
          font-family: "Manrope",sans-serif;
          font-weight: bold;
          font-size: 12px;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          color: $color_secondary_200;
        }
        a{
          color: white;
          padding:5px 0;
          font-size: 12px;
        }
      }
    }
    .bottom--footer_wapper{
      padding: 0;
      .bottom--footer{
        flex-direction: row;
        justify-content: space-between;
        .social--media_logo{
          width: auto;
          justify-content: initial;
          a{
            margin: 5px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px){
  footer {
    .footer--wrapper {
      flex-direction: row;
      justify-content: space-between;
      img {
        margin: 10px 100px 10px 0;
      }
    }
  }
}