@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600;700&family=Roboto:wght@100;400;700&display=swap');

@font-face {
  font-family: 'custom-font-crm';
  src:  url('custom_font/custom-font-crm.eot?hpqw9x');
  src:  url('custom_font/custom-font-crm.eot?hpqw9x#iefix') format('embedded-opentype'),
  url('custom_font/custom-font-crm.ttf?hpqw9x') format('truetype'),
  url('custom_font/custom-font-crm.woff?hpqw9x') format('woff'),
  url('custom_font/custom-font-crm.svg?hpqw9x#custom-font-crm') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="cf-"],
[class*=" cf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "custom-font-crm" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.45em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cf-rating-star-full:before {
  content: "\e901";
}
.cf-alert-circle-full:before {
  content: "\e902";
}
.cf-check-circle-full:before {
  content: "\e90b";
}
.cf-delete-circle-full:before {
  content: "\e90d";
}
.cf-information-circle-full:before {
  content: "\e90e";
}
.cf-single-neutral:before {
  content: "\e90a";
}
.cf-pin-location-1:before {
  content: "\e90c";
}
.cf-shipment-weight-kg:before {
  content: "\e90f";
}
.cf-appliances-fridge:before {
  content: "\e941";
}
.cf-pencil:before {
  content: "\e916";
}
.cf-social-media-twitter:before {
  content: "\e917";
}
.cf-social-video-youtube-clip:before {
  content: "\e918";
}
.cf-professional-network-linkedin:before {
  content: "\e919";
}
.cf-social-instagram:before {
  content: "\e91a";
}
.cf-social-media-facebook:before {
  content: "\e91b";
}
.cf-shop:before {
  content: "\e91c";
}
.cf-messages-bubble-square-text:before {
  content: "\e91d";
}
.cf-phone:before {
  content: "\e91e";
}
.cf-search:before {
  content: "\e91f";
}
.cf-temperature-snowflake:before {
  content: "\e920";
}
.cf-synchronize-arrow-1:before {
  content: "\e921";
}
.cf-view-1:before {
  content: "\e922";
}
.cf-pin-location-11:before {
  content: "\e923";
}
.cf-lock-6:before {
  content: "\e925";
}
.cf-list-bullets:before {
  content: "\e926";
}
.cf-information-circle:before {
  content: "\e928";
}
.cf-house-chimney:before {
  content: "\e929";
}
.cf-shrink-2:before {
  content: "\e92c";
}
.cf-expand-3:before {
  content: "\e92d";
}
.cf-envelope:before {
  content: "\e92e";
}
.cf-remove-circle:before {
  content: "\e930";
}
.cf-close:before {
  content: "\e931";
}
.cf-check-circle-1:before {
  content: "\e932";
}
.cf-check-1:before {
  content: "\e933";
}
.cf-calendar-search:before {
  content: "\e934";
}
.cf-calendar-check:before {
  content: "\e935";
}
.cf-briefcase:before {
  content: "\e936";
}
.cf-bin:before {
  content: "\e937";
}
.cf-barcode-scan:before {
  content: "\e938";
}
.cf-arrow-down-1:before {
  content: "\e939";
}
.cf-arrow-down:before {
  content: "\e93a";
}
.cf-arrow-up:before {
  content: "\e93b";
}
.cf-arrow-up-1:before {
  content: "\e93c";
}
.cf-arrow-left:before {
  content: "\e93d";
}
.cf-arrow-left-1:before {
  content: "\e93e";
}
.cf-arrow-right:before {
  content: "\e93f";
}
.cf-arrow-right-1:before {
  content: "\e940";
}
.cf-currency-euro-circle:before {
  content: "\e942";
}
.cf-add-circle:before {
  content: "\e943";
}
.cf-alert-circle:before {
  content: "\e944";
}
.cf-alarm-bell:before {
  content: "\e945";
}
.cf-password-lock-1:before {
  content: "\e900";
}
.cf-view-off:before {
  content: "\e903";
}
.cf-rating-star:before {
  content: "\e905";
}
.cf-question-circle:before {
  content: "\e906";
}
.cf-messages-bubble-square-question:before {
  content: "\e907";
}
.cf-headphones-customer-support-human:before {
  content: "\e909";
}

/// Custom picto
@font-face {
  font-family: 'icomoon';
  src:  url('custom_pictos/icomoon.eot?imq0nx');
  src:  url('custom_pictos/icomoon.eot?imq0nx#iefix') format('embedded-opentype'),
  url('custom_pictos/icomoon.ttf?imq0nx') format('truetype'),
  url('custom_pictos/icomoon.woff?imq0nx') format('woff'),
  url('custom_pictos/icomoon.svg?imq0nx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.45em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\eab5";
  color: #36506c;
}
.icon-bag .path1:before {
  content: "\e900";
  color: rgb(252, 237, 175);
}
.icon-bag .path2:before {
  content: "\e901";
  margin-left: -0.9775390625em;
  color: rgb(255, 207, 0);
}
.icon-bag .path3:before {
  content: "\e902";
  margin-left: -0.9775390625em;
  color: rgb(54, 80, 108);
}
.icon-bag .path4:before {
  content: "\e903";
  margin-left: -0.9775390625em;
  color: rgb(255, 227, 107);
}
.icon-bag .path5:before {
  content: "\e904";
  margin-left: -0.9775390625em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path1:before {
  content: "\e905";
  color: rgb(255, 207, 0);
}
.icon-buildings .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path4:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path5:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path6:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path7:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-buildings .path8:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-calendar .path1:before {
  content: "\e90d";
  color: rgb(255, 207, 0);
}
.icon-calendar .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-calendar .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-calendar .path4:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-calendar .path5:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-car-clouds .path1:before {
  content: "\e912";
  color: rgb(255, 255, 255);
}
.icon-car-clouds .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-car-clouds .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-car-clouds .path4:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-car-clouds .path5:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-car-clouds .path6:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path7:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path8:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path9:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path10:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path11:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path12:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-clouds .path13:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path1:before {
  content: "\e91f";
  color: rgb(255, 207, 0);
}
.icon-car-sun .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-car-sun .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-car-sun .path5:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path6:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path7:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path8:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path9:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path10:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-car-sun .path11:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path12:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path13:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path14:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path15:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-car-sun .path16:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-check-list .path1:before {
  content: "\e92f";
  color: rgb(255, 207, 0);
}
.icon-check-list .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-check-list .path3:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-check-list .path4:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-check-list .path5:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-check-list .path6:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-check-list .path7:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-check-list .path8:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-chocolate-bar .path1:before {
  content: "\e937";
  color: rgb(255, 207, 0);
}
.icon-chocolate-bar .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-chocolate-bar .path3:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-colis .path1:before {
  content: "\e93a";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-colis .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 227, 107);
}
.icon-colis .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: none;
}
.icon-colis .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: none;
}
.icon-colis .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-colis .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: none;
}
.icon-delivery-man-give-1 .path1:before {
  content: "\e940";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-delivery-man-give-1 .path2:before {
  content: "\e941";
  margin-left: -0.9794921875em;
  color: rgb(255, 207, 0);
}
.icon-delivery-man-give-1 .path3:before {
  content: "\e942";
  margin-left: -0.9794921875em;
  color: rgb(253, 248, 232);
}
.icon-delivery-man-give-1 .path4:before {
  content: "\e943";
  margin-left: -0.9794921875em;
  color: rgb(255, 227, 107);
}
.icon-delivery-man-give-1 .path5:before {
  content: "\e944";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-delivery-man-give-1 .path6:before {
  content: "\e945";
  margin-left: -0.9794921875em;
  color: rgb(253, 248, 232);
}
.icon-delivery-man-give-1 .path7:before {
  content: "\e946";
  margin-left: -0.9794921875em;
  color: rgb(253, 248, 232);
}
.icon-delivery-man-give-1 .path8:before {
  content: "\e947";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-delivery-man-give-1 .path9:before {
  content: "\e948";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-delivery-man-give-1 .path10:before {
  content: "\e949";
  margin-left: -0.9794921875em;
  color: rgb(255, 255, 255);
}
.icon-delivery-man-give-1 .path11:before {
  content: "\e94a";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-delivery-man-give-1 .path12:before {
  content: "\e94b";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-delivery-man-give-1 .path13:before {
  content: "\e94c";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-delivery-truck .path1:before {
  content: "\e94d";
  color: rgb(54, 80, 108);
}
.icon-delivery-truck .path2:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-delivery-truck .path3:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-delivery-truck .path4:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-delivery-truck .path5:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-delivery-truck .path6:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-delivery-truck .path7:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-delivery-truck .path8:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-e-commerce-basket-laptop .path1:before {
  content: "\e955";
  color: rgb(255, 255, 255);
}
.icon-e-commerce-basket-laptop .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-e-commerce-basket-laptop .path3:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-e-commerce-basket-laptop .path4:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-e-commerce-basket-laptop .path5:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-e-commerce-basket-laptop .path6:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-e-commerce-basket-laptop .path7:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-e-commerce-basket-laptop .path8:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-flux-optimises .path1:before {
  content: "\e95d";
  color: rgb(255, 255, 255);
}
.icon-flux-optimises .path2:before {
  content: "\e95e";
  margin-left: -0.9169921875em;
  color: rgb(252, 237, 175);
}
.icon-flux-optimises .path3:before {
  content: "\e95f";
  margin-left: -0.9169921875em;
  color: rgb(208, 221, 236);
}
.icon-flux-optimises .path4:before {
  content: "\e960";
  margin-left: -0.9169921875em;
  color: rgb(231, 238, 247);
  opacity: 0.7;
}
.icon-flux-optimises .path5:before {
  content: "\e961";
  margin-left: -0.9169921875em;
  color: rgb(231, 238, 247);
}
.icon-flux-optimises .path6:before {
  content: "\e962";
  margin-left: -0.9169921875em;
  color: rgb(255, 227, 107);
}
.icon-flux-optimises .path7:before {
  content: "\e963";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-flux-optimises .path8:before {
  content: "\e964";
  margin-left: -0.9169921875em;
  color: rgb(255, 227, 107);
}
.icon-flux-optimises .path9:before {
  content: "\e965";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-flux-optimises .path10:before {
  content: "\e966";
  margin-left: -0.9169921875em;
  color: rgb(99, 118, 145);
}
.icon-flux-optimises .path11:before {
  content: "\e967";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-flux-optimises .path12:before {
  content: "\e968";
  margin-left: -0.9169921875em;
  color: rgb(255, 255, 255);
}
.icon-flux-optimises .path13:before {
  content: "\e969";
  margin-left: -0.9169921875em;
  color: rgb(99, 118, 145);
}
.icon-flux-optimises .path14:before {
  content: "\e96a";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-flux-optimises .path15:before {
  content: "\e96b";
  margin-left: -0.9169921875em;
  color: none;
}
.icon-house-3 .path1:before {
  content: "\e96c";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-house-3 .path2:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 227, 107);
}
.icon-house-3 .path3:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(252, 237, 175);
}
.icon-house-3 .path4:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-house-3 .path5:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(253, 248, 232);
}
.icon-house-3 .path6:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-house-3 .path7:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-house-3 .path8:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(253, 248, 232);
}
.icon-house-3 .path9:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-house-3 .path10:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hub .path1:before {
  content: "\e976";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-hub .path2:before {
  content: "\e977";
  margin-left: -0.9794921875em;
  color: rgb(253, 248, 232);
}
.icon-hub .path3:before {
  content: "\e978";
  margin-left: -0.9794921875em;
  color: rgb(255, 207, 0);
}
.icon-hub .path4:before {
  content: "\e979";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hub .path5:before {
  content: "\e97a";
  margin-left: -0.9794921875em;
  color: rgb(255, 207, 0);
}
.icon-hub .path6:before {
  content: "\e97b";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hub .path7:before {
  content: "\e97c";
  margin-left: -0.9794921875em;
  color: rgb(255, 207, 0);
}
.icon-hub .path8:before {
  content: "\e97d";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hub .path9:before {
  content: "\e97e";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hub .path10:before {
  content: "\e97f";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hub .path11:before {
  content: "\e980";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hub .path12:before {
  content: "\e981";
  margin-left: -0.9794921875em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path1:before {
  content: "\e982";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-hubrider .path2:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(253, 248, 232);
}
.icon-hubrider .path3:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path4:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-hubrider .path5:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path6:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-hubrider .path7:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path8:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-hubrider .path9:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path10:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path11:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-hubrider .path12:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path1:before {
  content: "\e98e";
  color: rgb(255, 255, 255);
}
.icon-inter-magasins .path2:before {
  content: "\e98f";
  margin-left: -0.916015625em;
  color: rgb(99, 118, 145);
}
.icon-inter-magasins .path3:before {
  content: "\e990";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path4:before {
  content: "\e991";
  margin-left: -0.916015625em;
  color: rgb(255, 255, 255);
}
.icon-inter-magasins .path5:before {
  content: "\e992";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-inter-magasins .path6:before {
  content: "\e993";
  margin-left: -0.916015625em;
  color: rgb(253, 248, 232);
}
.icon-inter-magasins .path7:before {
  content: "\e994";
  margin-left: -0.916015625em;
  color: rgb(252, 237, 175);
}
.icon-inter-magasins .path8:before {
  content: "\e995";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path9:before {
  content: "\e996";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path10:before {
  content: "\e997";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path11:before {
  content: "\e998";
  margin-left: -0.916015625em;
  color: rgb(255, 207, 0);
}
.icon-inter-magasins .path12:before {
  content: "\e999";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path13:before {
  content: "\e99a";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path14:before {
  content: "\e99b";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-inter-magasins .path15:before {
  content: "\e99c";
  margin-left: -0.916015625em;
  color: rgb(253, 248, 232);
}
.icon-inter-magasins .path16:before {
  content: "\e99d";
  margin-left: -0.916015625em;
  color: rgb(252, 237, 175);
}
.icon-inter-magasins .path17:before {
  content: "\e99e";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path18:before {
  content: "\e99f";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-inter-magasins .path19:before {
  content: "\e9a0";
  margin-left: -0.916015625em;
  color: rgb(54, 80, 108);
}
.icon-iris-scan-search .path1:before {
  content: "\e9a1";
  color: rgb(54, 80, 108);
}
.icon-iris-scan-search .path2:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-iris-scan-search .path3:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-iris-scan-search .path4:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-iris-scan-search .path5:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-iris-scan-search .path6:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path1:before {
  content: "\e9a7";
  color: rgb(255, 255, 255);
}
.icon-livraison-sur-rdv .path2:before {
  content: "\e9a8";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-livraison-sur-rdv .path3:before {
  content: "\e9a9";
  margin-left: -0.908203125em;
  color: rgb(208, 221, 236);
}
.icon-livraison-sur-rdv .path4:before {
  content: "\e9aa";
  margin-left: -0.908203125em;
  color: rgb(231, 238, 247);
}
.icon-livraison-sur-rdv .path5:before {
  content: "\e9ab";
  margin-left: -0.908203125em;
  color: rgb(208, 221, 236);
}
.icon-livraison-sur-rdv .path6:before {
  content: "\e9ac";
  margin-left: -0.908203125em;
  color: rgb(253, 248, 232);
}
.icon-livraison-sur-rdv .path7:before {
  content: "\e9ad";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-livraison-sur-rdv .path8:before {
  content: "\e9ae";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path9:before {
  content: "\e9af";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path10:before {
  content: "\e9b0";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path11:before {
  content: "\e9b1";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-livraison-sur-rdv .path12:before {
  content: "\e9b2";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path13:before {
  content: "\e9b3";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path14:before {
  content: "\e9b4";
  margin-left: -0.908203125em;
  color: none;
}
.icon-livraison-sur-rdv .path15:before {
  content: "\e9b5";
  margin-left: -0.908203125em;
  color: rgb(99, 118, 145);
}
.icon-livraison-sur-rdv .path16:before {
  content: "\e9b6";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-livraison-sur-rdv .path17:before {
  content: "\e9b7";
  margin-left: -0.908203125em;
  color: none;
}
.icon-love-gift-flowers .path1:before {
  content: "\e9b8";
  color: rgb(54, 80, 108);
}
.icon-love-gift-flowers .path2:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-love-gift-flowers .path3:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-love-gift-flowers .path4:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-love-gift-flowers .path5:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-love-gift-flowers .path6:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-love-gift-flowers .path7:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-love-gift-flowers .path8:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-love-gift-flowers .path9:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-love-gift-flowers .path10:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-newspaper .path1:before {
  content: "\e9c2";
  color: rgb(255, 255, 255);
}
.icon-newspaper .path2:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-newspaper .path3:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-newspaper .path4:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-newspaper .path5:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-newspaper .path6:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-offre-adaptable .path1:before {
  content: "\e9c8";
  color: rgb(255, 255, 255);
}
.icon-offre-adaptable .path2:before {
  content: "\e9c9";
  margin-left: -0.9091796875em;
  color: rgb(252, 237, 175);
}
.icon-offre-adaptable .path3:before {
  content: "\e9ca";
  margin-left: -0.9091796875em;
  color: rgb(174, 194, 218);
}
.icon-offre-adaptable .path4:before {
  content: "\e9cb";
  margin-left: -0.9091796875em;
  color: rgb(208, 221, 236);
}
.icon-offre-adaptable .path5:before {
  content: "\e9cc";
  margin-left: -0.9091796875em;
  color: rgb(231, 238, 247);
}
.icon-offre-adaptable .path6:before {
  content: "\e9cd";
  margin-left: -0.9091796875em;
  color: none;
}
.icon-offre-adaptable .path7:before {
  content: "\e9ce";
  margin-left: -0.9091796875em;
  color: rgb(255, 255, 255);
}
.icon-offre-adaptable .path8:before {
  content: "\e9cf";
  margin-left: -0.9091796875em;
  color: rgb(253, 248, 232);
}
.icon-offre-adaptable .path9:before {
  content: "\e9d0";
  margin-left: -0.9091796875em;
  color: rgb(255, 207, 0);
}
.icon-offre-adaptable .path10:before {
  content: "\e9d1";
  margin-left: -0.9091796875em;
  color: rgb(54, 80, 108);
}
.icon-offre-adaptable .path11:before {
  content: "\e9d2";
  margin-left: -0.9091796875em;
  color: rgb(54, 80, 108);
}
.icon-offre-adaptable .path12:before {
  content: "\e9d3";
  margin-left: -0.9091796875em;
  color: rgb(54, 80, 108);
}
.icon-offre-adaptable .path13:before {
  content: "\e9d4";
  margin-left: -0.9091796875em;
  color: rgb(99, 118, 145);
}
.icon-offre-adaptable .path14:before {
  content: "\e9d5";
  margin-left: -0.9091796875em;
  color: rgb(54, 80, 108);
}
.icon-offre-adaptable .path15:before {
  content: "\e9d6";
  margin-left: -0.9091796875em;
  color: rgb(174, 194, 218);
}
.icon-offre-adaptable .path16:before {
  content: "\e9d7";
  margin-left: -0.9091796875em;
  color: rgb(174, 194, 218);
}
.icon-package .path1:before {
  content: "\e9d8";
  color: rgb(255, 207, 0);
}
.icon-package .path2:before {
  content: "\e9d9";
  margin-left: -0.9580078125em;
  color: rgb(54, 80, 108);
}
.icon-package .path3:before {
  content: "\e9da";
  margin-left: -0.9580078125em;
  color: rgb(54, 80, 108);
}
.icon-package .path4:before {
  content: "\e9db";
  margin-left: -0.9580078125em;
  color: rgb(54, 80, 108);
}
.icon-package .path5:before {
  content: "\e9dc";
  margin-left: -0.9580078125em;
  color: rgb(54, 80, 108);
}
.icon-payment .path1:before {
  content: "\e9dd";
  color: rgb(255, 207, 0);
}
.icon-payment .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-payment .path3:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-payment .path4:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-payment .path5:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-payment .path6:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-people .path1:before {
  content: "\e9e3";
  color: rgb(54, 80, 108);
}
.icon-people .path2:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-people .path3:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-people .path4:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-people .path5:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-phone-action-check .path1:before {
  content: "\e9e8";
  color: rgb(255, 207, 0);
}
.icon-phone-action-check .path2:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-action-check .path3:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-check .path4:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-check .path5:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-check .path6:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-download .path1:before {
  content: "\e9ee";
  color: rgb(255, 255, 255);
}
.icon-phone-action-download .path2:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-phone-action-download .path3:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-download .path4:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-download .path5:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-download .path6:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-download .path7:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-download .path8:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-heart .path1:before {
  content: "\e9f6";
  color: rgb(255, 255, 255);
}
.icon-phone-action-heart .path2:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-heart .path3:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-phone-action-heart .path4:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-phone-action-heart .path5:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-play .path1:before {
  content: "\e9fb";
  color: rgb(255, 255, 255);
}
.icon-play .path2:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path1:before {
  content: "\e9fd";
  color: rgb(255, 255, 255);
}
.icon-reverse-logistic .path2:before {
  content: "\e9fe";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-reverse-logistic .path3:before {
  content: "\e9ff";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-reverse-logistic .path4:before {
  content: "\ea00";
  margin-left: -0.908203125em;
  color: rgb(253, 248, 232);
}
.icon-reverse-logistic .path5:before {
  content: "\ea01";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-reverse-logistic .path6:before {
  content: "\ea02";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path7:before {
  content: "\ea03";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path8:before {
  content: "\ea04";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path9:before {
  content: "\ea05";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-reverse-logistic .path10:before {
  content: "\ea06";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path11:before {
  content: "\ea07";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path12:before {
  content: "\ea08";
  margin-left: -0.908203125em;
  color: rgb(99, 118, 145);
}
.icon-reverse-logistic .path13:before {
  content: "\ea09";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-reverse-logistic .path14:before {
  content: "\ea0a";
  margin-left: -0.908203125em;
  color: rgb(255, 255, 255);
}
.icon-shield-check-1 .path1:before {
  content: "\ea0b";
  color: rgb(255, 207, 0);
}
.icon-shield-check-1 .path2:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shield-check-1 .path3:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shield-check-1 .path4:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-ship-from-hub .path1:before {
  content: "\ea0f";
  color: rgb(255, 255, 255);
}
.icon-ship-from-hub .path2:before {
  content: "\ea10";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-ship-from-hub .path3:before {
  content: "\ea11";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-ship-from-hub .path4:before {
  content: "\ea12";
  margin-left: -0.908203125em;
  color: rgb(253, 248, 232);
}
.icon-ship-from-hub .path5:before {
  content: "\ea13";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-ship-from-hub .path6:before {
  content: "\ea14";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path7:before {
  content: "\ea15";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-ship-from-hub .path8:before {
  content: "\ea16";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path9:before {
  content: "\ea17";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-ship-from-hub .path10:before {
  content: "\ea18";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path11:before {
  content: "\ea19";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path12:before {
  content: "\ea1a";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path13:before {
  content: "\ea1b";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path14:before {
  content: "\ea1c";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path15:before {
  content: "\ea1d";
  margin-left: -0.908203125em;
  color: rgb(99, 118, 145);
}
.icon-ship-from-hub .path16:before {
  content: "\ea1e";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-hub .path17:before {
  content: "\ea1f";
  margin-left: -0.908203125em;
  color: rgb(255, 255, 255);
}
.icon-ship-from-store-1 .path1:before {
  content: "\ea20";
  color: rgb(255, 255, 255);
}
.icon-ship-from-store-1 .path2:before {
  content: "\ea21";
  margin-left: -0.9169921875em;
  color: rgb(252, 237, 175);
}
.icon-ship-from-store-1 .path3:before {
  content: "\ea22";
  margin-left: -0.9169921875em;
  color: rgb(208, 221, 236);
}
.icon-ship-from-store-1 .path4:before {
  content: "\ea23";
  margin-left: -0.9169921875em;
  color: rgb(231, 238, 247);
  opacity: 0.7;
}
.icon-ship-from-store-1 .path5:before {
  content: "\ea24";
  margin-left: -0.9169921875em;
  color: rgb(231, 238, 247);
}
.icon-ship-from-store-1 .path6:before {
  content: "\ea25";
  margin-left: -0.9169921875em;
  color: rgb(255, 227, 107);
}
.icon-ship-from-store-1 .path7:before {
  content: "\ea26";
  margin-left: -0.9169921875em;
  color: none;
}
.icon-ship-from-store-1 .path8:before {
  content: "\ea27";
  margin-left: -0.9169921875em;
  color: none;
}
.icon-ship-from-store-1 .path9:before {
  content: "\ea28";
  margin-left: -0.9169921875em;
  color: rgb(255, 207, 0);
}
.icon-ship-from-store-1 .path10:before {
  content: "\ea29";
  margin-left: -0.9169921875em;
  color: none;
}
.icon-ship-from-store-1 .path11:before {
  content: "\ea2a";
  margin-left: -0.9169921875em;
  color: rgb(255, 227, 107);
}
.icon-ship-from-store-1 .path12:before {
  content: "\ea2b";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store-1 .path13:before {
  content: "\ea2c";
  margin-left: -0.9169921875em;
  color: rgb(255, 227, 107);
}
.icon-ship-from-store-1 .path14:before {
  content: "\ea2d";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store-1 .path15:before {
  content: "\ea2e";
  margin-left: -0.9169921875em;
  color: none;
}
.icon-ship-from-store-1 .path16:before {
  content: "\ea2f";
  margin-left: -0.9169921875em;
  color: rgb(99, 118, 145);
}
.icon-ship-from-store-1 .path17:before {
  content: "\ea30";
  margin-left: -0.9169921875em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store-1 .path18:before {
  content: "\ea31";
  margin-left: -0.9169921875em;
  color: rgb(255, 255, 255);
}
.icon-ship-from-store-1 .path19:before {
  content: "\ea32";
  margin-left: -0.9169921875em;
  color: rgb(99, 118, 145);
}
.icon-ship-from-store .path1:before {
  content: "\ea33";
  color: rgb(255, 255, 255);
}
.icon-ship-from-store .path2:before {
  content: "\ea34";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-ship-from-store .path3:before {
  content: "\ea35";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-ship-from-store .path4:before {
  content: "\ea36";
  margin-left: -0.908203125em;
  color: rgb(253, 248, 232);
}
.icon-ship-from-store .path5:before {
  content: "\ea37";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-ship-from-store .path6:before {
  content: "\ea38";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store .path7:before {
  content: "\ea39";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store .path8:before {
  content: "\ea3a";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store .path9:before {
  content: "\ea3b";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-ship-from-store .path10:before {
  content: "\ea3c";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store .path11:before {
  content: "\ea3d";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store .path12:before {
  content: "\ea3e";
  margin-left: -0.908203125em;
  color: rgb(99, 118, 145);
}
.icon-ship-from-store .path13:before {
  content: "\ea3f";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-ship-from-store .path14:before {
  content: "\ea40";
  margin-left: -0.908203125em;
  color: rgb(255, 255, 255);
}
.icon-shipment-star-1 .path1:before {
  content: "\ea41";
  color: rgb(255, 207, 0);
}
.icon-shipment-star-1 .path2:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shipment-star-1 .path3:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shipment-star-1 .path4:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shipment-star-1 .path5:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shipment-star-2 .path1:before {
  content: "\ea46";
  color: rgb(54, 79, 107);
}
.icon-shipment-star-2 .path2:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-shipment-star-2 .path3:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shipment-star-2 .path4:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shipment-star-2 .path5:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shipment-star-2 .path6:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-shop-and-bag .path1:before {
  content: "\ea4c";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-shop-and-bag .path2:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(253, 248, 232);
}
.icon-shop-and-bag .path3:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(252, 237, 175);
}
.icon-shop-and-bag .path4:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-and-bag .path5:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-and-bag .path6:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-and-bag .path7:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-shop-and-bag .path8:before {
  content: "\ea53";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-and-bag .path9:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path1:before {
  content: "\ea55";
  color: rgb(255, 255, 255);
}
.icon-shop-cart .path2:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path3:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path4:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-shop-cart .path5:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path6:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path7:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path8:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path9:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop-cart .path10:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop .path1:before {
  content: "\ea5f";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-shop .path2:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(253, 248, 232);
}
.icon-shop .path3:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(252, 237, 175);
}
.icon-shop .path4:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop .path5:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shop .path6:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side .path1:before {
  content: "\ea65";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-shopping-bag-side .path2:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(253, 248, 232);
}
.icon-shopping-bag-side .path3:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(252, 237, 175);
}
.icon-shopping-bag-side .path4:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side .path5:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side .path6:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side .path7:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-shopping-bag-side .path8:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side .path9:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side2 .path1:before {
  content: "\ea6e";
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-shopping-bag-side2 .path2:before {
  content: "\ea6f";
  margin-left: -1.0205078125em;
  color: rgb(253, 248, 232);
}
.icon-shopping-bag-side2 .path3:before {
  content: "\ea70";
  margin-left: -1.0205078125em;
  color: rgb(252, 237, 175);
}
.icon-shopping-bag-side2 .path4:before {
  content: "\ea71";
  margin-left: -1.0205078125em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side2 .path5:before {
  content: "\ea72";
  margin-left: -1.0205078125em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side2 .path6:before {
  content: "\ea73";
  margin-left: -1.0205078125em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side2 .path7:before {
  content: "\ea74";
  margin-left: -1.0205078125em;
  color: rgb(255, 207, 0);
}
.icon-shopping-bag-side2 .path8:before {
  content: "\ea75";
  margin-left: -1.0205078125em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag-side2 .path9:before {
  content: "\ea76";
  margin-left: -1.0205078125em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag .path1:before {
  content: "\ea77";
  color: rgb(54, 80, 108);
}
.icon-shopping-bag .path2:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-bag .path3:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-shopping-bag .path4:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-cart .path1:before {
  content: "\ea7b";
  color: rgb(54, 80, 108);
}
.icon-shopping-cart .path2:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(196, 196, 196);
}
.icon-shopping-cart .path3:before {
  content: "\ea7d";
  margin-left: -1em;
  color: none;
}
.icon-shopping-cart .path4:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-cart .path5:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-cart .path6:before {
  content: "\ea80";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-shopping-cart .path7:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-cart .path8:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-shopping-cart .path9:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-shopping-cart .path10:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-smiley-happy .path1:before {
  content: "\ea85";
  color: rgb(255, 207, 0);
}
.icon-smiley-happy .path2:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(54, 79, 107);
}
.icon-smiley-happy .path3:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-smiley-happy .path4:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-smiley-happy .path5:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smiley-happy .path6:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path1:before {
  content: "\ea8b";
  color: rgb(255, 255, 255);
}
.icon-stockage-colis .path2:before {
  content: "\ea8c";
  margin-left: -0.908203125em;
  color: rgb(252, 237, 175);
}
.icon-stockage-colis .path3:before {
  content: "\ea8d";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
  opacity: 0.15;
}
.icon-stockage-colis .path4:before {
  content: "\ea8e";
  margin-left: -0.908203125em;
  color: rgb(253, 248, 232);
}
.icon-stockage-colis .path5:before {
  content: "\ea8f";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-stockage-colis .path6:before {
  content: "\ea90";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path7:before {
  content: "\ea91";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-stockage-colis .path8:before {
  content: "\ea92";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path9:before {
  content: "\ea93";
  margin-left: -0.908203125em;
  color: rgb(255, 207, 0);
}
.icon-stockage-colis .path10:before {
  content: "\ea94";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path11:before {
  content: "\ea95";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path12:before {
  content: "\ea96";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path13:before {
  content: "\ea97";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path14:before {
  content: "\ea98";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path15:before {
  content: "\ea99";
  margin-left: -0.908203125em;
  color: rgb(49, 194, 170);
}
.icon-stockage-colis .path16:before {
  content: "\ea9a";
  margin-left: -0.908203125em;
  color: rgb(54, 80, 108);
}
.icon-stockage-colis .path17:before {
  content: "\ea9b";
  margin-left: -0.908203125em;
  color: rgb(255, 255, 255);
}
.icon-team-meeting-1 .path1:before {
  content: "\ea9c";
  color: rgb(54, 80, 108);
}
.icon-team-meeting-1 .path2:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-team-meeting-1 .path3:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-team-meeting-1 .path4:before {
  content: "\ea9f";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-team-meeting-1 .path5:before {
  content: "\eaa0";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-team-meeting-1 .path6:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-team-meeting-1 .path7:before {
  content: "\eaa2";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-team-meeting-1 .path8:before {
  content: "\eaa3";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-team-meeting-1 .path9:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-time-clock-fire .path1:before {
  content: "\eaa5";
  color: rgb(255, 207, 0);
}
.icon-time-clock-fire .path2:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-time-clock-fire .path3:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-time-clock-fire .path4:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-time-clock-fire .path5:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-tree .path1:before {
  content: "\eaaa";
  color: rgb(255, 207, 0);
}
.icon-tree .path2:before {
  content: "\eaab";
  margin-left: -0.9599609375em;
  color: rgb(54, 80, 108);
}
.icon-tree .path3:before {
  content: "\eaac";
  margin-left: -0.9599609375em;
  color: rgb(54, 80, 108);
}
.icon-tree .path4:before {
  content: "\eaad";
  margin-left: -0.9599609375em;
  color: rgb(255, 255, 255);
}
.icon-tree .path5:before {
  content: "\eaae";
  margin-left: -0.9599609375em;
  color: rgb(54, 80, 108);
}
.icon-tree .path6:before {
  content: "\eaaf";
  margin-left: -0.9599609375em;
  color: rgb(54, 80, 108);
}
.icon-wine-opener .path1:before {
  content: "\eab0";
  color: rgb(255, 255, 255);
}
.icon-wine-opener .path2:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-wine-opener .path3:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
.icon-wine-opener .path4:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(255, 207, 0);
}
.icon-wine-opener .path5:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(54, 80, 108);
}
