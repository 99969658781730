.loader--action {
  display: none;
  // position: fixed;
  height: 50px;
  bottom: 70px;
  right: 10px;
  width: 100%;
  border-radius: 4px;
  background-color: $color_primary_300;

  .loader--action_content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    p {
      color: $color_secondary_400;
      font-size: 1em;
      font-family: "manrope", sans-serif;
    }

    .progress--bar {
      background: $color_primary_200;
      border-radius: 0 0 4px 4px;
      width: 100%;
      position: absolute;
      display: flex;
      height: 5px;
      bottom: 0;
    }

    .progress--value {
      animation: load 3s normal infinite;
      background: white;
      height: 5px;
      width: 0;
    }

    @keyframes load {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 560px){
  .loader--action{
    width: 117px;
  }
}