@mixin transition-hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@mixin transition-hover_quick {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}


@mixin btn--style {
  display: flex;
  padding: 20px 40px;
  color: white;
  background: $blue--main-color;
  border-radius: 5px;
  margin: 20px 5px 0;
  text-decoration: none;
  font-size: 1.35em;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: transparent;
  border: none;
}

@mixin line--under-title {
  position: absolute;
  content: "";
  bottom: -15px;
  left: 0;
  max-width: 60px;
  width: 100%;
  border-radius: 8px;
  height: 7px;
  margin: 0 auto;
  right: 0;
}

@mixin slick--arrow {
  color: transparent;
  background-color: transparent;
  border: none;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  top: 0;
  outline: none;
  &:after {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    position: absolute;
    top: 40%;
    align-items: center;
    padding: 6px;
    font-size: 2em;
    font-family: 'Material Icons';
  }

}

@mixin slick--prev_after {
  left: -35px;
  content: "\e5c4";
  justify-content: flex-end;
}

@mixin slick--next_after {
  right: -35px;
  content: "\e5c8";
  justify-content: flex-start;
}

@mixin card--style {
  border-radius: 0 0 5px 5px;
  background-color: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin tooptilps {
  position: absolute;
  right: -42px;
  background: $transparence--bg;
  color: $blue--help;
  border-radius: 7px;
  border: 1px solid $blue--help;
  padding: 12px;
  bottom: -80px;
  width: 260px;
  font-size: 0.90em;;
  opacity: 0;
  @include transition-hover_quick;
}

@mixin special--input {
  .form--group {
    position: relative;
    width: 100%;
  }
  .has--search .form--control {
    width: 100%;
    display: flex;
    padding: 15px 0 15px 2rem;
    margin: 0 auto;
    border-radius: 2px;
    border: 1px solid $color_secondary_200;
    outline: none;
    caret-color: $color_secondary_200;
    color: $color_secondary_400;

    &::placeholder {
      color: $color_secondary_300;
    }
  }
  .form--control:focus {
    border: 1px solid $color_highlight_400;
    outline: none;
    caret-color: $color_highlight_400;
  }
  .has--search i {
    font-size: 0.8rem;
    position: absolute;
    z-index: 2;
    color: $color_secondary_300;
    font-weight: 300;
    align-items: center;
    display: flex;
    left: 12px;
    height: 100%;
  }
}

@mixin classic--input {
  @include special--input;
  appearance: auto;
  .has--search .form--control {
    padding: 15px;
  }
}

@mixin generalBtn {
  border: none;
  padding: 15px;
  position:relative;
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(54, 80, 108, 0.15);
  background: linear-gradient(98.15deg, #233F53 1.58%, #36506C 57.12%);
  background-size: 200% 100%;
  transition: background 0.15s, color 0.25s;
  span{
    color: white;
    position: relative;
    z-index: 2;
  }
  &:before {
    border-radius: 4px;
    top: 0;
    left: 0;
    right: -1px;
    bottom: 0;
    transform-origin: center top;
    transform: scale(1, 0) translateZ(0);
    transition: all 0.25s ease-out;
    content: '';
    display: block;
    position: absolute;
    background-color: $color_primary_400;
  }
  &:hover {
    span {
      color: $color_secondary_400;
    }
    i{
      color:$color_secondary_400;
    }
    &:before { transform: scale(1) translateZ(0); }
  }
}

@mixin backBtn {
  @include generalBtn;
  background: white;
  box-shadow: none;
  border: 1px solid $color_secondary_400;
  color: $color_secondary_400;
  span{
    color: $color_secondary_400;
    z-index: 2;
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: center top;
    transform: scale(1, 0) translateZ(0);
    transition: all 0.25s ease-out;
    content: '';
    display: block;
    position: absolute;
    border-radius: 4px;
    background-color: $color_primary_200;
  }
  &:hover {
    border-radius: 4px;
    border-color: $color_primary_400;
    color: $color_secondary_400;
    i{
      color:$color_secondary_400;
    }
    &:before { transform: scale(1) translateZ(0); }
  }
}

@mixin cbxStyle {
  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      font-family: 'manrope', sans-serif;

      &:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 3px;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid $color_secondary_200;
        transition: all 0.2s ease;
        margin-right: 8px;
      }

      &:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: $color_secondary_400;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
    }
    span.label--desc{
      font-size: 0.75rem;
      margin-left: 22px;
      line-height: 130%;
      width: 50%;
      margin-top: 5px;
      font-weight: 400;
      color:$color_secondary_300;
    }
    span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: $color_primary_400;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    span:last-child {
      padding-left: 8px;
    }

    &:hover span:first-child {
      border-color: $color_primary_400;
    }
  }
  .inp-cbx:checked + .cbx span:first-child {
    background: $color_primary_400;
    border-color: $color_primary_400;
    animation: wave 0.4s ease;
    &:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .cbx span.color--svg-error{
    border-color: #FA6666;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

@mixin radiobtn {
  .radiobtn {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);

      &:first-child {
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid $color_secondary_200;
        transition: all 0.2s ease;
      }

      &:first-child svg {
        display: none;
      }
    }

    span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: $color_primary_400;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    &:hover span:first-child {
      background: white;
      border: 4px solid $color_primary_400;
    }
  }

  .inp-radiobtn:checked + .radiobtn span:first-child {
    background: white;
    border: 3px solid $color_primary_400;
    box-shadow: 0px 1px 4px rgba(255, 207, 0, 0.25), inset 0px 1px 2px rgba(54, 80, 108, 0.1);
    animation: wave 0.4s ease;

    &:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .inp-radiobtn:checked + .radiobtn span:first-child svg {
    stroke-dashoffset: 0;
  }


  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

@mixin popover {
  font-family: "roboto", sans-serif;
  color: $color_secondary_400;
  padding: 16px;
  .popover-header {
    i {
      color: $color_highlight_400;
      margin-right: 8px;
    }
    border-bottom: none;
    font-weight: bold;
    padding: 0;
    font-size: 1em;
    display: flex;
    color: $color_secondary_400;
    align-items: center;
    background: transparent;
  }
  .popover-body {
    font-size: 0.85em;
    font-weight: 400;
    color: $color_secondary_400;
    padding: 0;
    margin-top: 10px;
  }
}

@mixin typeAheadTwitter() {
  width: 100%;
  .tt-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  .tt-menu.tt-open {
    position: absolute;
    top: 40px !important;
    left: 0;
    width: 100%;
    font-family: "roboto", sans-serif;
    font-weight: 700;
    color: $color_secondary_400;
    background: white;
    border-radius: 2px;
    border: 1px solid $color_highlight_400;
    border-top: none;
    display: none;
    overflow: scroll;
    height: 200px;
  }
  .tt-suggestion.tt-selectable {
    cursor: pointer;
    padding: 15px;
    p {
      color: $color_secondary_400;
      font-size: 0.85em;
    }
    &:hover{
      background-color: $color_highlight_100;
      padding: 15px;
    }
  }

  .render--rp_main {
    // margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 15px;
      position: relative !important;
      left: 0 !important;
    }

  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $color_secondary_200;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
}
