@mixin text_style_h_4_mobile {
  font-size:1.5rem;
  font-family:"Manrope",sans-serif;
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_overline_mobile {
  font-size:0.75rem;
  font-family:"Manrope",sans-serif;
}
@mixin text_style_headline_h_1 {
  font-size:5.875rem;
  font-family:"Manrope",sans-serif;
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_h_2 {
  font-size:3.688rem;
  font-family:"Manrope",sans-serif;
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_h_3 {
  font-size:2.938rem;
  font-family:"Manrope",sans-serif;
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_h_4 {
  font-size:2.375rem;
  font-family:"Manrope",sans-serif;
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_h_5 {
  font-size:1.5rem;
  font-family:"Manrope",sans-serif;
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_h_6 {
  font-size:1.25rem;
  font-family:"Manrope",sans-serif;
  font-weight:bold;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_headline_h_7 {
  font-size:1.125rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_headline_h_8 {
  font-size:1rem;
  font-family:"Manrope",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_subtitle_sub_1 {
  font-size:1rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_subtitle_sub_2 {
  font-size:1rem;
  font-family:"Manrope",sans-serif;
}
@mixin text_style_body_p_1 {
  font-size:1rem;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_body_p_2 {
  font-size:0.875rem;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_body_p_0 {
  font-size:1.125rem;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_button_big {
  font-size:1rem;
  font-family:"Roboto",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_button_medium_small {
  font-size:0.875rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_caption_caption_1 {
  font-size:0.75rem;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:129.99999523162842%;
}
@mixin text_style_overline_overline_1 {
  font-size:0.75rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_overline_overline_2 {
  font-size:0.875rem;
  font-family:"Manrope",sans-serif;
}