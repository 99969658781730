$main--color : #303036;
$second--main-color: #FFCF00;
$blue--main-color: #1c366b;

$red--main: #e84436;
$blue--main: #3BB5F5;
$blue--title: #233667;
$blue--help:#1660C6;
$transparence--bg:#F1F7FF;

$grey--border:#E7E7F2;
$comment-bg:#F0F2F5;
$yellow-bg: #fff9de;
$yellow-content: #FFCF00;
$orange-border: #FF9211;
$red--error:#FA6666;

$turquoise--content: #3FC1C9;
$grey--text: #303030;
$grey--legend:#999999;
$grey--subtitle :#58595A;
$grey--canceled :#DBDEE3;
$orange--mail:#FC5130;
$red--error:#FA6666;


// webApp colors
$color_primary_700: rgba(114,81,17,1);
$color_primary_600: rgba(159,115,29,1);
$color_primary_500: rgba(231,187,0,1);
$color_primary_400: rgba(255,207,0,1);
$color_primary_300: rgba(255,227,107,1);
$color_primary_200: rgba(252,237,175,1);
$color_primary_100: rgba(253,248,232,1);
$color_secondary_700: rgba(6,22,28,1);
$color_secondary_600: rgba(15,37,46,1);
$color_secondary_500: rgba(35,63,83,1);
$color_secondary_400: rgba(54,80,108,1);
$color_secondary_300: rgba(99,118,145,1);
$color_secondary_200: rgba(174,194,218,1);
$color_secondary_100: #F5F9FF;
$color_secondary_50:  #E7EEF7;
$color_success_100: rgba(232,249,251,1);
$color_success_400: rgba(49,194,170,1);
$color_error_700: rgba(160,43,48,1);
$color_error_400: rgb(250, 102, 102);
$color_error_100: rgba(254,244,241,1);
$color_warning_700: rgba(187,91,37,1);
$color_warning_400: rgba(255,146,17,1);
$color_warning_100: rgba(255,242,227,1);
$color_highlight_700: rgba(19,53,78,1);
$color_highlight_400: rgba(68,164,234,1);
$color_highlight_100: rgba(237,246,253,1);
$color_white: rgba(255,255,255,1);
$color_grey_100: rgba(245,249,255,1);
$color_grey_200: rgba(223,229,237,1);
$color_grey_300: rgba(192,200,211,1);
$color_grey_400: rgba(143,149,158,1);
$hub_theme: #31C2AA;
$shopper_theme: #FA6666;
$riders_theme: $color_secondary_400;
$rider--theme_400 : #44A4EA;
$color_ttc: #AEC2DA;

$color_ttc: #AEC2DA;

@mixin text_style_headline_1 {
  font-size:94px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_2 {
  font-size:59px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_3 {
  font-size:47px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_4 {
  font-size:33px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_5 {
  font-size:24px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_6 {
  font-size:20px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_subtitle_1 {
  font-size:1rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_subtitle_2 {
  font-size:0.9rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_body_1 {
  font-size:16px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_body_2 {
  font-size:14px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_button_big {
  font-size:16px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_button_medium_small {
  font-size:14px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_caption {
  font-size:12px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150px;
}
@mixin text_style_overline {
  font-size:12px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin effect_style_button_elevation {
  box-shadow: 0.00px 4.00px 10.00px rgba(54, 80, 108, 0.150);
}